import React from 'react';
import Helmet from 'react-helmet';

import { alt, metaBranch } from '../lib/brand';
import Layout from '../components/Layout';
import Header from '../components/Header';
import BranchDetail from '../components/BranchDetail';

import { graphql } from 'gatsby';

const Branch = ({ data }) => {
   const { profile, siteMeta } = data;
   const {
      title,
      dbaLogoBlack,
      contact,
      profile: branchProfile,
      headBeginScript,
      headEndScript,
      bodyBeginScript,
      bodyEndScript
   } = profile;
   const {
      siteMetadata: { title: siteTitle }
   } = siteMeta;

   // ensure loan officers and teams are not null.
   profile.loanOfficers = Array.isArray(profile.loanOfficers) ? profile.loanOfficers : [];
   profile.teams = Array.isArray(profile.teams) ? profile.teams : [];

   let relatedProps = {
      siteTitle,
      profile
   };

   const pageTitle = alt(`Bay Equity ${title}`);

   const header = <Header active={[]} theme="white-ribbon" dbaBlack={dbaLogoBlack} clearDba={true} />;

   return (
      <Layout
         header={header}
         headBeginScript={headBeginScript}
         headEndScript={headEndScript}
         bodyBeginScript={bodyBeginScript}
         bodyEndScript={bodyEndScript}>
         <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={metaBranch(title)} />
            <meta http-equiv="X-UA-Compatible" content="IE=11" />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <BranchDetail
            {...profile}
            {...contact}
            {...branchProfile}
            {...relatedProps}
            isPreview={false}
            corporateBlogs={profile.news}
         />
      </Layout>
   );
};

export default Branch;

export const pageQuery = graphql`
   query getBranchPageWithOfficersById($id: String!) {
      profile(id: { eq: $id }) {
         id
         firestoreId
         slug
         headBeginScript {
            name
            src
            async
            html
         }
         headEndScript {
            name
            src
            async
            html
         }
         bodyBeginScript {
            name
            src
            async
            html
         }
         bodyEndScript {
            name
            src
            async
            html
         }
         title
         owner
         dbaLogoBlack
         profile {
            name
            email
            licenseIds {
               license
               id
            }
         }
         email
         phone
         mobilePhone
         address1
         address2
         address3
         city
         state
         zip
         lat
         lon
         hero {
            quote
            byline
            customPhoto
            heroBackground
            photoAlignment
         }
         contact {
            phone
            mobilePhone
            fax
            facebook
            instagram
            linkedin
            twitter
            youtube
         }
         blogs {
            id
            html
            slug
            title
            author {
               slug
               profile {
                  name
               }
               gallery {
                  photo
               }
            }
            date
            feature
            categories
            image
         }
         news {
            id
            html
            slug
            title
            date
            feature
            categories
            image
         }
         loanOfficers {
            slug
            hidden
            gallery {
               photo
            }
            profile {
               name
               email
               jobTitle
               licenseIds {
                  license
                  id
               }
            }
         }
         teams {
            slug
            hidden
            profile {
               name
               email
               logo
               licenseIds {
                  license
                  id
               }
            }
         }
         staff {
            photo
            name
            title
            license
            email
         }
         managers {
            photo
            name
            title
            license
            email
         }
         modules {
            type
            display
            videos {
               videoTitle
               videoCaption
               videoUrl
            }
            facebookPageUrl
            bookings
            calendar
            useBackgroundImage
            backgroundImage
            backgroundImageCustom
            darkTitleText
            instagramToken
            resourceIntro
            resourceList {
               resourceFile
               resourceUrl
               resourceDescription
            }
            event1 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            event2 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            event3 {
               eventImage
               eventCustomImage
               eventName
               eventDate
               eventTime
               eventLocation
               eventDescription
               eventUrl
               eventCta
            }
            awards {
               awardDescription
               awardYear
               awardImage
               awardBadge
            }
            social_id
            social_page
            showCalculatorMortgage
            showCalculatorRentVsBuy
            showCalculatorHomeAffordability
            showCalculatorRequiredIncome
            showCalculatorRefinance
            contentImage
            contentTitle
            contentText
            profileShowCompactView
            profileTitle
            profileBody
            contactFormFields {
               type
               required
               customFieldType
               label
               bammName
               options {
                  hasCustomLabel
                  label
                  value
               }
            }
            contactFormTitle
            contactUsText
            contactUsTitle
            contactUsBammUrl
            contactUsLeadEmailCcList
            contactUsConfirmEmailText
            contactUsConfirmEmailFrom
            id
         }
      }
      siteMeta: site {
         siteMetadata {
            title
         }
      }
   }
`;
